<template>
  <Navbar />
  <ScrollButton />

  <section
    class="banner-title"
    style="background-image: url(images/world-banner.png); "
  >
    <div class="auto-container">
      <div class="row clearfix">
        <div class="title-column col-md-6 col-sm-6 col-xs-12">
          <h1>İletişim</h1>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
          <ul class="bread-crumb clearfix">
            <li>
              <a href="#">Anasayfa</a>
            </li>
            <li class="active">İletişim</li>
          </ul>
        </div>
        <!-- Col end -->
      </div>
      <!-- Row end -->
    </div>
    <!-- Banner text end -->
  </section>
  <!-- Banner area end -->

  <div class="body-inner">
    <section id="main-container" class="main-container">
      <div class="container">
        <div class="text-uppercase line-wrapper">
        <div class="line"></div>
        <div class="text">Lokasyonumuz</div>
        <div class="line"></div>
      </div>
        <!--/ Title row end -->

        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="ts-service-box-bg text-center h-100">
              <span class="ts-service-icon icon-round">
                <font-awesome-icon :icon="['fas', 'map-marker-alt']" />
              </span>
              <div class="ts-service-box-content">
                <h4>Adres</h4>
                <p>
                  Muallimköy Mh. Deniz Cd. <br />Bilişim Vadisi 2B Blok D:110
                </p>
                <p>Gebze/KOCAELİ</p>
              </div>
            </div>
          </div>
          <!-- Col 1 end -->

          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="ts-service-box-bg text-center h-100">
              <span class="ts-service-icon icon-round">
                <font-awesome-icon :icon="['fas', 'envelope']" />
              </span>
              <div class="ts-service-box-content">
                <h4>Emaıl</h4>
                <p>intekotomasyon@gmail.com</p>
                <p>info@intekbilisim.com.tr</p>
              </div>
            </div>
          </div>
          <!-- Col 2 end -->

          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="ts-service-box-bg text-center h-100">
              <span class="ts-service-icon icon-round">
                <font-awesome-icon :icon="['fas', 'phone']" />
              </span>
              <div class="ts-service-box-content">
                <h4>Telefon</h4>
                <p>(+90) 262 502 0893</p>
                <p>(+90) 555 887 1241</p>
              </div>
            </div>
          </div>
          <!-- Col 3 end -->
        </div>
        <!-- 1st row end -->

        <div class="gap-60"></div>
        <div class="ratio ratio-16x9">
          <div class="ratio ratio-16x9">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d6569.27594182819!2d29.500603062629793!3d40.782251100467285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sMuallimk%C3%B6y%20Mh.%20Deniz%20Cd.%20Bili%C5%9Fim%20Vadisi%202B%20Blok%20!5e1!3m2!1str!2str!4v1713476336692!5m2!1str!2str"
              width="600"
              height="450"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>

        <div class="gap-40"></div>
      </div>
      <!-- Conatiner end -->
    </section>
    <!-- Main container end -->
  </div>
  <!-- Body inner end -->

  <Footer />
</template>
  
  
  <script>
import { ref, onMounted } from "vue";
import { firestore } from "@/firebase/config";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { notify } from "@kyvg/vue3-notification";
import { useRouter } from "vue-router";
import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar-2.vue";
import ScrollButton from "@/components/ScrollButton.vue";

export default {
  components: {
    Loading,
    Footer,
    Navbar,
    ScrollButton,
  },
};
</script>
  <style scoped>
.btn-submit {
  background: #001e57;
  color: #fff;
}
@media (max-width: 767px) {
  .col-sm-12 {
    margin-bottom: 20px;
  }
}
.banner-title{
  background-attachment: scroll;
  background-size: auto;
  
}
</style>