<template>
  <div class="carousel slide" id="mySlide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button
        type="button"
        data-bs-target="#mySlide"
        data-bs-slide-to="0"
        class="carousel-indicator-item active"
        aria-current="true"
        aria-label="ilk slayt"
      ></button>

      <button
        type="button"
        data-bs-target="#mySlide"
        data-bs-slide-to="1"
        class="carousel-indicator-item "
        aria-label="ikinci slayt"
      ></button>
      <button
        type="button"
        data-bs-target="#mySlide"
        data-bs-slide-to="2"
        class="carousel-indicator-item "
        aria-label="üçüncü slayt"
      ></button>
      <button
        type="button"
        data-bs-target="#mySlide"
        data-bs-slide-to="3"
        class="carousel-indicator-item "
        aria-current="true"
        aria-label="dördüncü slayt"
      ></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <a href="https://intekelectronics.com.tr/" target="_blank"><img src="images/4.png" class="d-block w-100" alt="slide-1"/>
        <div class="carousel-caption d-none d-md-block">
        </div>
      </a>
      </div>
      <div class="carousel-item">
        <img src="images/2.png" class="d-block w-100" alt="slide-2" />
        <div class="carousel-caption d-none d-md-block">
          <h2>Özel Üretim Sıcaklık Sensörleri</h2>
          <p></p> 
        </div>
      </div>
      <div class="carousel-item">
        <img src="images/3.png" class="d-block w-100" alt="slide-3" />
        <div class="carousel-caption d-none d-md-block"></div>
      </div>
      <div class="carousel-item">
        <img src="images/5.png" class="d-block w-100" alt="slide-4" />
        <div class="carousel-caption d-none d-md-block">
          <h2></h2>
        <p></p> 
        </div>
      </div>
    </div>

    <button
      type="button"
      class="carousel-control-prev"
      data-bs-target="#mySlide"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Önceki</span>
    </button>
    <button
      type="button"
      class="carousel-control-next"
      data-bs-target="#mySlide"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Sonraki</span>
    </button>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style scoped>
</style>
  
  